<template>
    <div class="editcoupon-container">
        <div class="top">
    
            <div class="fields">
                
                <div class="field" >
                    <p>Nome</p>
                    <vs-input type="text" v-model="coupon.name" :disabled="coupon.id != null"/>
                </div>

                <div class="field">
                    <p>Utilizzi massimi</p>
                    <vs-input type="text" v-model="coupon.maximum_uses" :disabled="coupon.id != null"/>
                </div>

                <div class="field">
                    <p>Sconto (%)</p>
                    <vs-input type="text" v-model="coupon.discount_percentage" :disabled="coupon.id != null"/>
                </div>
    
                <vs-button v-if="coupon.id == null" size="large" :loading="loading" @click="editCouponEvent">{{ $t('common.saveChanges').toUpperCase() }}</vs-button>
                <vs-button v-if="coupon.id != null" danger border size="large" :loading="deleting" @click="deleteCouponEvent">{{ $t('common.delete').toUpperCase() }}</vs-button>
    
            </div>
        </div>
    
    </div>
    </template>
    
    <script>
    import {
        apiCall,
        baseUri
    } from '../../utils/ApiMiddleware';
    export default {
        name: "editCouponEl",
        props: {
            coupon: {
                type: Object,
                required: true
            },
            editCoupon: {
                type: Function,
                required: true
            },
    
            user: {
                type: Object,
                required: false,
                default: null
            },
    
            deleted: {
                type: Function,
                required: true
            }
        },
        components: {},
        data() {
            return {
                loading: false,
                languages: [],
                lang: null,
                baseUri,
                deleting: false,
            }
        },
    
        async mounted() {
           
        },
    
        methods: {
    
            async deleteCouponEvent() {
                this.deleting = true;
                const response = await apiCall('DELETE', '/backoffice/coupons/' + this.coupon.id);
                console.log(response);
                this.deleting = false;
                // if status code is 200, show a success message 
                if (response.status == 200) {
                    this.$vs.notification({
                        title: this.$t('common.messages.success'),
                        text: 'Coupon eliminato con successo',
                        color: 'success',
                        position: 'top-right'
                    });
                    this.deleted();
                } else if (response.status != 0) {
                    this.$vs.notification({
                        title: this.$t('common.messages.somethingWentWrong'),
                        text: 'Impossibile eliminare il coupon',
                        color: 'danger',
                        position: 'top-right'
                    });
                }
    
            },
    
            async editCouponEvent() {
    
                this.loading = true;
                var temp = JSON.parse(JSON.stringify(this.coupon));

                if(parseFloat(this.coupon.discount_percentage) < 5 || parseFloat(this.coupon.discount_percentage) > 90){
                    this.$vs.notification({
                        title: this.$t('common.messages.error'),
                        text: 'Lo sconto deve essere compreso tra 5 e 90',
                        color: 'danger',
                        position: 'top-right'
                    });
                    this.loading = false;
                    return;
                }
                
                await this.editCoupon(temp);
                this.loading = false;
            },
    
        }
    }
    </script>
    
    <style scoped>
    .header {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    
    }
    
    .top {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    .fields {
        display: flex;
        flex-direction: column;
    }
    
    .field {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 30px;
        border-bottom: 1px solid var(--grey-75);
    }
    
    .field p {
        width: 35%;
        max-width: 35%;
    }
    
    .editcoupon-container {
    
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
        height: calc(100% - 75px);
    
        justify-content: space-between;
    }
    
    /* MOBILE */
    @media (max-width: 600px) {
        .editcoupon-container {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 20px;
            box-sizing: border-box;
    
        }
    
    }
    
    textarea {
        border-radius: 5px;
        border: 1px solid var(--grey-75);
        height: 150px;
        max-height: 150px;
        padding: 10px;
        font-family: Poppins;
    }
    </style><style>
    .editcoupon-container .field .vs-input {
        background: transparent !important;
        font-size: 1rem;
    }
    
    .editcoupon-container .field .vs-select__input {
        background: transparent !important;
        font-size: 1rem;
    }
    
    .editcoupon-container .field input::-webkit-date-and-time-value {
        text-align: left !important;
        font-size: 1rem;
    }
    </style>
    