import { render, staticRenderFns } from "./Coupons.vue?vue&type=template&id=2655c741&scoped=true&"
import script from "./Coupons.vue?vue&type=script&lang=js&"
export * from "./Coupons.vue?vue&type=script&lang=js&"
import style0 from "./Coupons.vue?vue&type=style&index=0&id=2655c741&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2655c741",
  null
  
)

export default component.exports